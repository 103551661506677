import { useRecoilState } from 'recoil'
import { quantityState } from '../../common/atoms/checkout'
import { ChangeEvent } from 'react'

type Props = {
  max: number
}

export function useQuantity({ max }: Props) {
  const [quantity, setQuantity] = useRecoilState(quantityState)

  // useEffect(() => {
  //   if (product?.mode === ProductModeEnum.RANKUP && quantity > 1) {
  //     setQuantity(1)
  //   }
  // }, [product])

  const onChangeN = (event: ChangeEvent<HTMLInputElement>) => {
    if (+event.currentTarget.value <= max) {
      setQuantity(+event.currentTarget.value)
    }
  }

  return { quantity, onChangeN, setQuantity }
}
